<template>
  <v-dialog v-model="dialog" fullscreen>
    <template #activator="{ on }">
      <wx-btn-standard
        v-on="on"
        :disabled="disabled"
        :small="smallActivatorBtn"
        :color="activatorBtnColor"
        :style="activatorBtnStyle"
        :class="activatorBtnClass"
        class="dialog-activator-btn"
      >
        <v-icon left>mdi-cloud-upload-outline</v-icon>
        {{ title }}
      </wx-btn-standard>
    </template>

    <v-btn @click="dialog = false" :title="$t('common.closeWindowHoverTitle')" class="close-btn" icon large>
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-form ref="uploadForm" class="dialog-content" lazy-validation>
      <h2 class="wx-typo-h1 mb-0">{{ title }}</h2>

      <v-alert
        v-if="this.uploadInstructionTexts"
        color="warning"
        class="instruction-text mt-3 mt-sm-4 mt-md-6 mb-n1"
        outlined
        text
        dismissible
      >
        <ol class="my-n2">
          <li v-for="(text, index) in uploadInstructionTexts" :key="index" class="my-2 color-text-theme">{{ text }}</li>
        </ol>
      </v-alert>

      <div class="info-box my-6 my-sm-8 my-lg-8">
        <div class="info-box__icon">
          <v-icon>mdi-file-search-outline</v-icon>
        </div>
        <div class="info-box__text">
          <v-file-input
            :label="getFileInputLabel()"
            :rules="[() => validateFile()]"
            :key="resetFileInputKey"
            @change="selectFile"
            accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            counter
            show-size
          >
            <template v-slot:selection="{ index }">
              <v-chip v-if="index < 2" color="accent-4" label small>
                {{ formatName() }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
      </div>

      <div
        v-if="uploadWarning"
        class="text-before-action d-flex align-start text-center mb-4 mb-sm-7 mb-mb-10"
        outlined
        text
      >
        <v-icon color="warning" class="mr-1">mdi-timer-outline</v-icon>
        <p class="mt-sm-1 mb-0 text-left">{{ uploadWarning }}</p>
      </div>

      <footer class="actions d-flex flex-column flex-sm-row justify-center pb-8">
        <div class="download-cta d-flex align-center justify-center justify-sm-end mr-sm-4">
          <p class="string mb-0 mr-1 mr-sm-2 wx-typo-sm">{{ $t("common.fileUpload.downloadTemplateButton") }}</p>
          <wx-btn-standard
            @click="fetchData"
            :title="$t('common.fileUpload.downloadTemplateButton')"
            color="secondary"
            class="my-2 mx-0"
            outlined
          >
            <v-icon dense>mdi-file-excel-outline</v-icon>
            <v-icon dense>mdi-cloud-download</v-icon>
          </wx-btn-standard>
        </div>

        <wx-btn-standard @click="confirmFile" color="primary" class="my-2 ml-sm-4">
          <v-icon left>mdi-cloud-upload</v-icon>
          {{ $t("common.fileUpload.uploadButton") }}
        </wx-btn-standard>
      </footer>
    </v-form>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import FileService from "./FileService";
import ErrorHandling from "./ErrorHandling";
import { mapGetters } from "vuex";

export default {
  name: "DialogFileUpload",
  components: {
    WxBtnStandard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    uploadInstructionTexts: {
      type: Array,
      required: true,
    },
    uploadWarning: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxFileSizeInMB: {
      type: Number,
      default: 10,
    },
    smallActivatorBtn: {
      type: Boolean,
      default: false,
    },
    activatorBtnClass: {
      type: String,
      default: () => "",
    },
    activatorBtnColor: {
      type: String,
      default: () => null,
    },
    activatorBtnStyle: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      dialog: false,
      fileToUpload: null,
      resetFileInputKey: 0,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryId"]),
  },
  methods: {
    getFileInputLabel() {
      return this.fileToUpload ? " " + this.$t("common.fileUpload.selectedFile") : this.$t("common.fileUpload.noFile");
    },
    selectFile(file) {
      this.fileToUpload = file;
    },
    formatName() {
      if (this.fileToUpload) {
        return this.fileToUpload.name;
      }
    },
    validateFile() {
      if (this.fileToUpload) {
        let size = this.fileToUpload.size / (1024 * 1024); // Get it in MegaBytes.
        if (size > this.maxFileSizeInMB) {
          return this.$t("common.fileUpload.errors.fileTooBig", { maxFileSizeInMB: this.maxFileSizeInMB });
        }
        return true;
      }
      return this.$t("common.fileUpload.errors.noFile");
    },
    confirmFile() {
      let valid = this.$refs.uploadForm.validate();
      if (valid) {
        this.$emit("selectedFile", this.fileToUpload);
        this.resetFileInputKey++;
        this.dialog = false;
      }
    },
    fetchData() {
      this.exportInProgress = true;
      FileService.downloadTemplate(this.activeFactoryId)
        .then((httpResponse) => this.handleDataExportResponse(httpResponse))
        .catch((httpResponse) => this.handleDataExportError(httpResponse));
    },
    handleDataExportResponse(httpResponse) {
      let file = window.URL.createObjectURL(new Blob([httpResponse.data]));
      let docUrl = document.createElement("a");
      docUrl.href = file;
      docUrl.setAttribute("download", "export.xlsx");
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
      this.exportInProgress = false;
    },
    handleDataExportError(httpResponse) {
      this.exportInProgress = false;
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.close();
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);
}

.close-btn {
  position: fixed;
  z-index: 1;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}

.dialog-content {
  width: auto;
  max-width: 800px;
  padding: var(--grid-gutter);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.info-box {
  display: flex;
  align-items: stretch;
  width: 100%;
  border: 1px solid var(--color-border-theme);
  border-radius: var(--border-radius-lg);

  &__icon {
    align-self: center;
    width: auto;
    height: 100%;
    margin-left: -1px;
    padding: 3%;
    border-radius: var(--border-radius-lg);
    border: 0 solid var(--color-border-theme);

    .v-icon:before {
      font-size: 2em;
    }
  }
  &__text {
    align-self: center;
    text-align: center;
    flex-grow: 1;
    padding: 4%;

    ::v-deep .v-input.v-file-input {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  @media ($wx-sm-min) {
    &__icon {
      border-width: 1px;
      border-left-width: 0;

      .v-icon:before {
        font-size: 3em;
      }
    }
    &__text {
      padding-left: 3%;
    }
  }
}

.actions {
  .download-cta {
    .string {
      text-align: right;

      @media ($wx-xs-max) {
        &:after {
          content: "\2192"; // arrow
          display: inline-block;
          padding-left: 0.25em;
        }
      }
    }
  }
}
</style>
