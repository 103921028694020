<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("product.title") }}</h1>

    <product-list />
  </article>
</template>

<script>
import ProductList from "../components/product/ProductList";

export default {
  components: {
    ProductList,
  },
};
</script>
