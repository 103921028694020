<template>
  <fragment>
    <header class="action-container">
      <v-row>
        <v-col cols="12" sm="8" md="6" lg="5" xl="4" class="order-2 order-sm-1">
          <wx-text-field
            v-model.trim="search"
            @change="onSearchKeywordChange"
            :label="$t('common.search')"
            :rules="[() => validateSearchKeyword()]"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            clearable
          />
          <div class="mt-6 wx-typo-sm">
            <span class="ml-1 font-weight-light">{{ $t("product.puFilterOptionTitle") }}</span>
            <wx-autocomplete
              v-model="productionUnitId"
              @change="onNewProductionUnit"
              :items="availableProductionUnitSelection"
              item-value="value"
              item-text="text"
              class="mt-2 mb-1"
              hide-details="true"
              validate-on-blur
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="6"
          lg="7"
          xl="8"
          class="action-buttons order-1 order-sm-2 d-flex flex-wrap justify-center justify-sm-end align-sm-end"
        >
          <wx-btn-standard :disabled="!exportButtonEnable" @click="exportProducts()" class="mb-2 mb-sm-1">
            <v-icon left>mdi-cloud-download-outline</v-icon>
            {{ $t("product.export.button") }}
          </wx-btn-standard>
          <dialog-file-upload
            :title="$t('product.import.button')"
            :upload-instruction-texts="instructions"
            :upload-warning="warning"
            @selectedFile="upload"
            activator-btn-class="mb-2 mb-sm-1 mx-2"
          />
          <wx-btn-standard :to="toProductCreation()" class="mb-2 mb-sm-1">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("product.wizard.creation.buttonNew") }}
          </wx-btn-standard>
        </v-col>
      </v-row>
      <div class="mb-0"> </div>

      <div class="pu-selector-container d-flex flex-column flex-sm-row justify-sm-space-between"> </div>
    </header>

    <v-container>
      <v-alert type="error" prominent v-model="bulkProductImportErrorOccurred">
        <p class="info-box-text">{{ $t("common.attention") }}</p>
        <ul class="errors-list">
          <li v-for="(value, index) in bulkProductImportErrors" :key="index">{{ value }}</li>
        </ul>
        <v-btn
          @click="
            alert = false;
            bulkProductImportErrorOccurred = false;
            bulkProductImportErrors = [];
          "
          :title="$t('common.close')"
          class="close-btn"
          icon
          large
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-alert>
    </v-container>

    <wx-data-table
      :headers="computedProductColumns"
      :items="filteredComputedProducts"
      :items-per-page="productsPerPage"
      :search="search"
      :filterable="true"
      :sort-by="['sku', 'name', 'category']"
      :sort-desc="[false, true, true]"
      :no-results-text="this.$t('common.noMatchingRecords')"
      :hide-default-footer="filteredComputedProducts.length <= productsPerPage"
      :footer-props="{
        itemsPerPageText: this.$t('product.listing.itemsPerPage'),
        itemsPerPageAllText: this.$t('common.listing.showAllOption'),
      }"
      :title="getRowTitle()"
      @click:row="redirectToEdition"
      mobile-breakpoint="sm"
      class="wx-panel"
      v-if="!loadingProducts"
    >
      <template #[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ $t("common.listing.itemOfItems") }} {{ items.itemsLength }}
      </template>

      <template #[`item.actions`]="{ item }">
        <wx-btn-icon :aria-label="$t('common.edit')" :to="toProductEdition(item.id)" class="my-sm-2 mr-4" small>
          <v-icon small>mdi-pencil</v-icon>
        </wx-btn-icon>
        <!-- 
          Delete btn
          -->
        <dialog-confirm-deletion
          :deletion-title="$t('product.wizard.deletion.title')"
          :info-box-text-title="$t('common.attention')"
          :info-box-text="getProductDeletionInfoText()"
          :confirm-text-prefix="getProductDeletionConfirmationPrefix()"
          :element-display-name="getProductToDeleteDisplayName(item)"
          :small-activator-btn="true"
          :delete-button-text="$t('product.wizard.deletion.deleteProductBtn')"
          @confirm="deleteProduct(item)"
          activator-btn-class="my-sm-2 mx-0"
        />
      </template>
      <template #no-data>
        {{ $t("product.listing.noData") }}
      </template>
    </wx-data-table>
    <wx-skeleton-loader v-else type="image" />
  </fragment>
</template>

<script>
import ProductService from "@/components/product/ProductService";
import ErrorHandling from "@/components/ErrorHandling";
import DialogConfirmDeletion from "@/components/DialogConfirmDeletion";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxDataTable from "@/components/ui/WxDataTable";
import WxTextField from "@/components/ui/WxTextField";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import RouteService from "@/router/RouteService";
import { mapActions, mapGetters } from "vuex";
import DialogFileUpload from "@/components/DialogFileUpload";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import DemoService from "@/components/DemoService";
import WxSkeletonLoader from "@/components/ui/WxSkeletonLoader.vue";

export default {
  name: "ProductList",
  components: {
    WxAutocomplete,
    DialogConfirmDeletion,
    DialogFileUpload,
    WxBtnStandard,
    WxDataTable,
    WxTextField,
    WxBtnIcon,
    WxSkeletonLoader,
  },
  data() {
    return {
      search: this.searchKeyword,
      productionUnitId: this.selectedProductionUnitId ? this.selectedProductionUnitId : "any",
      products: [],
      filteredComputedProducts: [],
      productsPerPage: 15,
      bulkProductImportErrorOccurred: false,
      bulkProductImportErrors: [],
      exportInProgress: false,
      exportButtonEnable: true,
      maxSearchLength: 50,
      loadingProducts: true,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    ...mapGetters("productAdmin", ["searchKeyword", "selectedProductionUnitId"]),
    ...mapGetters("user", ["email"]),
    computedProductColumns() {
      return [
        {
          text: this.$t("product.listing.column.sku"),
          align: "start",
          value: "sku",
          class: "col-sku",
        },
        { text: this.$t("common.name"), value: "name", class: "col-name" },
        { text: this.$t("product.listing.column.category"), value: "category" },
        {
          text: this.$t("common.actions"),
          value: "actions",
          class: "col-actions two-btn-wide",
          sortable: false,
          align: "right",
        },
      ];
    },
    computedProducts() {
      return this.products.map((p, index) => {
        let associationCount;
        if (p.associated_production_units.length > 0) {
          associationCount = p.associated_production_units.length;
        } else {
          associationCount = this.$t("product.listing.noAssociations");
        }
        return {
          id: p.id,
          sku: p.sku,
          name: DemoService.maskProductNameIfNecessary(this.email, p.description, index + 1),
          category: p.category,
          associationCount: associationCount,
          associatedProductionUnits: p.associated_production_units,
        };
      });
    },
    availableProductionUnitSelection() {
      let options = [];
      options.push({
        value: "any",
        text: this.$t("product.anyPU"),
      });
      if (this.activeFactoryProductionUnits) {
        let puList = this.activeFactoryProductionUnits
          .map((pu, index) => ({
            value: pu.id,
            text: DemoService.maskProductionUnitNameIfNecessary(this.email, pu.name, index + 1),
          }))
          .sort((p1, p2) => (p1.name <= p2.name ? 1 : -1));
        options.push(...puList);
      }
      return options;
    },
    instructions() {
      return [this.$t("product.import.instruction"), this.$t("product.import.instruction2")];
    },
    warning() {
      return this.$t("product.import.warning");
    },
  },
  watch: {
    activeFactory() {
      this.setSearchKeyword("");
      this.setSelectedProductionUnitId(null);
      this.products = [];
      this.filteredComputedProducts = [];
      this.bulkProductImportErrorOccurred = false;
      this.bulkProductImportErrors = [];
      this.loadProducts();
    },
  },
  methods: {
    ...mapActions("productAdmin", ["setSearchKeyword", "setSelectedProductionUnitId"]),
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    validateSearchKeyword() {
      const keyword = this.search && this.search.trim().length > 0 ? this.search.trim() : null;
      if (keyword && keyword.length > this.maxSearchLength) {
        this.exportButtonEnable = false;
        return this.$t("common.errors.keywordMaxLength", { maxLength: this.maxSearchLength });
      }
      this.exportButtonEnable = true;
      return true;
    },
    onSearchKeywordChange() {
      const keyword = this.search && this.search.trim().length > 0 ? this.search.trim() : null;
      this.setSearchKeyword(keyword);
    },
    toProductCreation() {
      return RouteService.toProductCreation();
    },
    toProductEdition(productId) {
      return RouteService.toProductEdition(productId);
    },
    onNewProductionUnit() {
      if (!this.productionUnitId || this.productionUnitId === "any") {
        this.setSelectedProductionUnitId(null);
        this.filteredComputedProducts = this.computedProducts;
      } else {
        this.setSelectedProductionUnitId(this.productionUnitId);
        this.filteredComputedProducts = this.computedProducts.filter((pu) => this.containsSelectedPuId(pu));
      }
    },
    containsSelectedPuId(computedPU) {
      const found = computedPU.associatedProductionUnits.find((pu) => pu.production_unit_id === this.productionUnitId);
      return !!found;
    },
    getRowTitle() {
      return this.$t("product.rowTitle");
    },
    redirectToEdition(product) {
      this.$router.push(RouteService.toProductEdition(product.id));
    },
    loadProducts() {
      if (this.activeFactory) {
        this.loadingProducts = true;
        ProductService.getProducts(this.activeFactory.id)
          .then((response) => this.handleProductsRetrievalResponse(response))
          .catch((error) => this.handleProductsRetrievalError(error.response));
      }
    },
    handleProductsRetrievalResponse(httpResponse) {
      this.products = httpResponse.data;
      this.onNewProductionUnit(); // Sets the `filteredComputedProducts`
      this.loadingProducts = false;
    },
    handleProductsRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.loadingProducts = false;
    },
    getErrorMessage(code, args, message) {
      if (code === "DSH_PROD_DELETE_10001" || code === "CS_PROD_DELETE_20004") {
        const regex = /sku '(.*)' is currently selected on Production Unit '(.*)';/gm;
        let matches = regex.exec(message);
        let sku = !!matches && matches.length >= 3 ? matches[1] : "";
        let puName = !!matches && matches.length >= 3 ? matches[2] : "";
        return this.$t("product.errors.productInSkuPreventingDeletion", { sku: sku, puName: puName });
      }
      return this.$t("common.errors.default", { code: code });
    },
    getProductDeletionInfoText() {
      return this.$t("product.wizard.deletion.warningMessage");
    },
    getProductDeletionConfirmationPrefix() {
      return this.$t("product.wizard.deletion.confirmMessagePrefix");
    },
    getProductToDeleteDisplayName(productToDelete) {
      const productNameComplement = productToDelete.name ? " - " + productToDelete.name + "" : "";
      return productToDelete.sku + productNameComplement;
    },
    deleteProduct(product) {
      ProductService.deleteProduct(product.id)
        .then((response) => this.handleProductDeletionResponse(response, product.sku))
        .catch((error) => this.handleProductDeletionError(error.response));
    },
    handleProductDeletionResponse(httpResponse, sku) {
      if (httpResponse.status === 204) {
        this.showOperationSuccess(this.$t("product.successfullyDeleted", { sku: sku }));
        this.loadProducts();
      }
    },
    handleProductDeletionError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    upload(file) {
      if (this.activeFactory) {
        ProductService.uploadProducts(this.activeFactory.id, file)
          .then((response) => this.handleProductsUploadResponse(response))
          .catch((error) => this.handleProductsUploadError(error.response));
      }
    },
    handleProductsUploadResponse(httpResponse) {
      if (httpResponse.status === 201) {
        this.bulkProductImportErrorOccurred = false;
        this.bulkProductImportErrors = [];
        this.showOperationSuccess(
          this.$t("product.successfullyUploaded", {
            created_product_count: httpResponse.data.count_created_products,
            updated_product_count: httpResponse.data.count_updated_products,
          }),
        );
        this.loadProducts();
      }
    },
    handleProductsUploadError(httpResponse) {
      let errors = httpResponse.data.errors.map((e) => e.message);
      if (errors && errors.length > 0) {
        let maximumNumberOfErrorsToDisplay = 10;
        if (errors.length > maximumNumberOfErrorsToDisplay) {
          this.bulkProductImportErrors = errors.slice(0, maximumNumberOfErrorsToDisplay);
        } else {
          this.bulkProductImportErrors = errors;
        }
        this.bulkProductImportErrorOccurred = true;
      } else {
        this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      }
    },
    exportProducts() {
      this.exportInProgress = true;
      ProductService.downloadProducts(this.activeFactory.id, this.selectedProductionUnitId, this.searchKeyword)
        .then((httpResponse) => this.handleProductExportResponse(httpResponse))
        .catch((httpResponse) => this.handleProductExportError(httpResponse));
    },
    handleProductExportResponse(httpResponse) {
      let file = window.URL.createObjectURL(new Blob([httpResponse.data]));
      let docUrl = document.createElement("a");
      docUrl.href = file;
      docUrl.setAttribute("download", "export.xlsx");
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
      this.exportInProgress = false;
    },
    handleProductExportError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
  },
  mounted() {
    this.search = this.searchKeyword;
    this.productionUnitId = this.selectedProductionUnitId ? this.selectedProductionUnitId : "any";
    this.loadProducts();
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  margin-bottom: 20px;
}

::v-deep table th {
  &.col-sku {
    width: 18%;
  }
  &.col-actions {
    width: 18%;
  }
}

// v-alert
.info-box-text {
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
}

.errors-list {
  list-style-type: circle;
  padding-left: 2rem;

  li {
    padding-left: 1rem;
  }
}

.close-btn {
  position: absolute;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}
</style>
